import {
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePrevious } from '@volvo-cars/react-utils';
import throttle from 'lodash/throttle';

export type Dimensions = {
  height: number;
  width: number;
};

export const useElementDimensions = <T extends HTMLElement = HTMLDivElement>({
  disabled = false,
  throttle: throttleValue = 0,
}: { disabled?: boolean; throttle?: number } = {}): [
  MutableRefObject<T | null>,
  Dimensions
] => {
  const ref = useRef<T | null>(null);
  let prevHeight = usePrevious(0);
  let prevWidth = usePrevious(0);

  const [dimensions, setDimensions] = useState<Dimensions>({
    height: 0,
    width: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDimensions = useCallback(
    throttle(() => {
      const newDimensions = ref?.current?.getBoundingClientRect();

      const newHeight = newDimensions?.height;
      if (!newHeight) {
        return;
      }

      const newWidth = newDimensions?.width;
      if (!newWidth) {
        return;
      }

      if (newHeight !== prevHeight || newWidth !== prevWidth) {
        setDimensions({
          height: newHeight,
          width: newWidth,
        });
        prevHeight = newHeight;
        prevWidth = newWidth;
      }
    }, throttleValue),
    []
  );

  useEffect(() => {
    updateDimensions();
    if (disabled) return;
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [disabled, updateDimensions]);

  return [ref, dimensions];
};
