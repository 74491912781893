import { type BrandedIconProps } from './branded-icon';
import { type IconProps } from './icon';
import { type IconButtonProps } from './icon-button';
import { type BrandedIconName, type IconName } from './icons.generated';

/**
 * @deprecated Migrate to `IconName` from `@volvo-cars/react-icons` instead.
 */
type DEPRECATED_IconButtonIcon =
  | 'alarm'
  | 'avatar'
  | 'calendar'
  | 'checkmark'
  | 'communication-chat'
  | 'communication-email'
  | 'communication-phone'
  | 'copy'
  | 'delete'
  | 'edit'
  | 'externallink'
  | 'favorite'
  | 'filter'
  | 'info'
  | 'list'
  | 'map-mappin'
  | 'map-route'
  | 'media-pause'
  | 'media-play'
  | 'more'
  | 'navigation-arrowback'
  | 'navigation-arrowdown'
  | 'navigation-arrowforward'
  | 'navigation-arrowup'
  | 'navigation-chevronback'
  | 'navigation-chevrondown'
  | 'navigation-chevronforward'
  | 'navigation-chevronup'
  | 'navigation-close'
  | 'navigation-minus'
  | 'navigation-plus'
  | 'other-bookmark'
  | 'other-eye'
  | 'other-eyeslash'
  | 'other-help'
  | 'other-home'
  | 'print'
  | 'refresh'
  | 'save'
  | 'search'
  | 'settings'
  | 'shop'
  | 'support'
  | 'time';

/**
 * 0.x icon types that are supported in the automatic transform to 1.0 icon names,
 * excluding the icons prefixed with `thirdparty-`.
 *
 * @deprecated Migrate to `IconName` and separate `size` property instead.
 */
type DEPRECATED_IconType =
  | '30daystrial-40'
  | '360camera-32'
  | '360camera-40'
  | 'alarm-16'
  | 'alarm-24'
  | 'alarm-32'
  | 'alarm-40'
  | 'alarm-64'
  | 'avatar-16'
  | 'avatar-24'
  | 'avatar-40'
  | 'award-24'
  | 'award-40'
  | 'blocked-16'
  | 'blocked-24'
  | 'blocked-32'
  | 'blocked-40'
  | 'blocked-48'
  | 'calendar-16'
  | 'calendar-24'
  | 'calendar-40'
  | 'calendar-64'
  | 'car-alcolock-40'
  | 'car-aqi-32'
  | 'car-aqi-40'
  | 'car-audio-24'
  | 'car-awd-16'
  | 'car-awd-24'
  | 'car-awd-32'
  | 'car-awd-40'
  | 'car-battery-24'
  | 'car-battery-40'
  | 'car-batterycharging-32'
  | 'car-batterycharging-40'
  | 'car-batterysoftware-40'
  | 'car-blis-32'
  | 'car-blis-40'
  | 'car-camera-24'
  | 'car-camera-32'
  | 'car-camera-40'
  | 'car-car-12'
  | 'car-car-16'
  | 'car-car-24'
  | 'car-car-32'
  | 'car-car-40'
  | 'car-car-48'
  | 'car-carbattery-40'
  | 'car-carclock-24'
  | 'car-carclock-40'
  | 'car-cardroplets-24'
  | 'car-cardroplets-40'
  | 'car-carelectricavatar-32'
  | 'car-carelectricavatar-40'
  | 'car-carelectricclock-32'
  | 'car-carelectricclock-40'
  | 'car-carelectricthreearrows-32'
  | 'car-carelectricthreearrows-40'
  | 'car-carelectrictwoarrows-32'
  | 'car-carelectrictwoarrows-40'
  | 'car-carinhouse-32'
  | 'car-carinhouse-40'
  | 'car-carinspection-24'
  | 'car-carinspection-40'
  | 'car-carmagnifier-24'
  | 'car-carmagnifier-40'
  | 'car-carshieldtick-24'
  | 'car-carshieldtick-40'
  | 'car-carside-24'
  | 'car-carside-32'
  | 'car-carside-40'
  | 'car-carunderroof-32'
  | 'car-carunderroof-40'
  | 'car-carwash-24'
  | 'car-carwash-40'
  | 'car-ccs-40'
  | 'car-charging-32'
  | 'car-charging-40'
  | 'car-chargingsocketcn-40'
  | 'car-chargingsocketcn-64'
  | 'car-chargingsocketcneu-40'
  | 'car-chargingsocketcneu-64'
  | 'car-chargingsocketeu-40'
  | 'car-chargingsocketeu-64'
  | 'car-chargingsocketjp-40'
  | 'car-chargingsocketjp-64'
  | 'car-chargingsocketjpna-40'
  | 'car-chargingsocketjpna-64'
  | 'car-chargingsocketna-40'
  | 'car-chargingsocketna-64'
  | 'car-citysafety-32'
  | 'car-citysafety-40'
  | 'car-citysafety-48'
  | 'car-collisionavoidence-32'
  | 'car-collisionavoidence-40'
  | 'car-courtesycar-24'
  | 'car-courtesycar-40'
  | 'car-cta-32'
  | 'car-cta-40'
  | 'car-driveralert-24'
  | 'car-driveralert-32'
  | 'car-driveralert-40'
  | 'car-electric-16'
  | 'car-electric-24'
  | 'car-electric-32'
  | 'car-electric-40'
  | 'car-electricalplug-24'
  | 'car-electricalplug-40'
  | 'car-engine-24'
  | 'car-engine-40'
  | 'car-engine-48'
  | 'car-fan-24'
  | 'car-fan-32'
  | 'car-fan-40'
  | 'car-fan-48'
  | 'car-fan-64'
  | 'car-fan-80'
  | 'car-features-16'
  | 'car-features-40'
  | 'car-features-48'
  | 'car-fuel-16'
  | 'car-fuel-24'
  | 'car-fuel-40'
  | 'car-fwd-16'
  | 'car-fwd-24'
  | 'car-fwd-32'
  | 'car-fwd-40'
  | 'car-houseelectricplug-32'
  | 'car-houseelectricplug-40'
  | 'car-hud-32'
  | 'car-hud-40'
  | 'car-hybrid-16'
  | 'car-hybrid-24'
  | 'car-hybrid-32'
  | 'car-hybrid-40'
  | 'car-key-24'
  | 'car-key-40'
  | 'car-moonroof-32'
  | 'car-moonroof-40'
  | 'car-navigate-16'
  | 'car-navigate-24'
  | 'car-navigate-32'
  | 'car-navigate-40'
  | 'car-performance-24'
  | 'car-performance-40'
  | 'car-plugsurfingcard-24'
  | 'car-plugsurfingcard-40'
  | 'car-psquare-32'
  | 'car-psquare-40'
  | 'car-road-16'
  | 'car-road-24'
  | 'car-road-32'
  | 'car-road-40'
  | 'car-roadsideassistance-24'
  | 'car-roadsideassistance-40'
  | 'car-roadsideassistance-64'
  | 'car-safetyfeatures-32'
  | 'car-seat-16'
  | 'car-seat-24'
  | 'car-seat-40'
  | 'car-seatright-24'
  | 'car-seatright-40'
  | 'car-seats-16'
  | 'car-seats-24'
  | 'car-seats-40'
  | 'car-seatsfour-24'
  | 'car-seatsfour-32'
  | 'car-seatsfour-40'
  | 'car-seatsseven-32'
  | 'car-service-24'
  | 'car-service-40'
  | 'car-size-24'
  | 'car-size-40'
  | 'car-slipperyroad-32'
  | 'car-slipperyroad-40'
  | 'car-speedcap-40'
  | 'car-steeringwheel-32'
  | 'car-steeringwheel-40'
  | 'car-steeringwheelp-32'
  | 'car-steeringwheelp-40'
  | 'car-towbar-40'
  | 'car-traffic-24'
  | 'car-traffic-40'
  | 'car-transmission-16'
  | 'car-transmission-24'
  | 'car-transmission-40'
  | 'car-trunkspace-24'
  | 'car-trunkspace-40'
  | 'car-type2-40'
  | 'car-tyre-24'
  | 'car-tyre-40'
  | 'car-wheel-16'
  | 'car-wheel-24'
  | 'car-windscreenwiper-24'
  | 'car-windscreenwiper-40'
  | 'checkmark-12'
  | 'checkmark-16'
  | 'checkmark-24'
  | 'checkmark-32'
  | 'checkmark-40'
  | 'checkmark-48'
  | 'circleplus-24'
  | 'circleplus-40'
  | 'circleplusfilled-24'
  | 'circleplusfilled-40'
  | 'citydriving-16'
  | 'citydriving-32'
  | 'citydriving-40'
  | 'coffee-40'
  | 'communication-chat-16'
  | 'communication-chat-24'
  | 'communication-chat-32'
  | 'communication-chat-40'
  | 'communication-email-16'
  | 'communication-email-24'
  | 'communication-email-32'
  | 'communication-email-40'
  | 'communication-mailhandling-40'
  | 'communication-phone-16'
  | 'communication-phone-24'
  | 'communication-phone-40'
  | 'communication-textmessage-40'
  | 'connectivity-16'
  | 'connectivity-24'
  | 'connectivity-40'
  | 'connectivity-80'
  | 'copy-16'
  | 'copy-40'
  | 'date-40'
  | 'delete-16'
  | 'delete-24'
  | 'delete-40'
  | 'designyourown-16'
  | 'designyourown-24'
  | 'designyourown-32'
  | 'designyourown-40'
  | 'device-24'
  | 'device-32'
  | 'device-40'
  | 'digitalkey-32'
  | 'digitalkey-40'
  | 'ecoleather-40'
  | 'edit-16'
  | 'edit-24'
  | 'edit-40'
  | 'electrification-16'
  | 'electrification-32'
  | 'electrification-40'
  | 'error-12'
  | 'error-16'
  | 'error-24'
  | 'error-40'
  | 'error-64'
  | 'extendedperiod-24'
  | 'extendedperiod-32'
  | 'extendedperiod-40'
  | 'extendedperiod-48'
  | 'externallink-12'
  | 'externallink-16'
  | 'externallink-24'
  | 'externallink-32'
  | 'externallink-40'
  | 'externallink-48'
  | 'externallink-64'
  | 'externallink-80'
  | 'facemask-40'
  | 'fallbackimage-24'
  | 'favorite-16'
  | 'favorite-24'
  | 'favorite-32'
  | 'favorite-40'
  | 'favorite-48'
  | 'filter-16'
  | 'filter-24'
  | 'filter-40'
  | 'filterfilled-32'
  | 'filterfilled-40'
  | 'guardingservices-24'
  | 'guardingservices-40'
  | 'highwaypilot-32'
  | 'highwaypilot-40'
  | 'incentives-40'
  | 'info-12'
  | 'info-16'
  | 'info-24'
  | 'info-32'
  | 'info-40'
  | 'insurance-24'
  | 'insurance-40'
  | 'insurance-80'
  | 'leaf-32'
  | 'leaf-40'
  | 'link-16'
  | 'link-24'
  | 'link-32'
  | 'link-40'
  | 'list-16'
  | 'list-24'
  | 'list-32'
  | 'list-40'
  | 'locked-40'
  | 'locked-64'
  | 'map-crosshair-32'
  | 'map-crosshair-40'
  | 'map-map-24'
  | 'map-map-40'
  | 'map-mappin-16'
  | 'map-mappin-24'
  | 'map-mappin-32'
  | 'map-mappin-40'
  | 'map-mappin-48'
  | 'map-mappin-64'
  | 'map-mappin-80'
  | 'map-pegman-32'
  | 'map-pegman-40'
  | 'map-route-16'
  | 'map-route-24'
  | 'map-route-32'
  | 'map-route-40'
  | 'media-pause-16'
  | 'media-pause-24'
  | 'media-pause-32'
  | 'media-pause-40'
  | 'media-pause-48'
  | 'media-pause-64'
  | 'media-pause-80'
  | 'media-play-16'
  | 'media-play-24'
  | 'media-play-32'
  | 'media-play-40'
  | 'media-play-48'
  | 'media-play-64'
  | 'media-play-80'
  | 'media-stepbackward-32'
  | 'media-stepbackward-40'
  | 'media-stepforward-32'
  | 'media-stepforward-40'
  | 'mixeddriving-16'
  | 'mixeddriving-32'
  | 'mixeddriving-40'
  | 'moneycirculararrows-32'
  | 'moneycirculararrows-40'
  | 'more-16'
  | 'more-24'
  | 'more-40'
  | 'more-48'
  | 'mountains-24'
  | 'mountains-32'
  | 'mountains-40'
  | 'multiplechoice-40'
  | 'navigation-arrowback-16'
  | 'navigation-arrowback-40'
  | 'navigation-arrowdown-16'
  | 'navigation-arrowdown-40'
  | 'navigation-arrowforward-16'
  | 'navigation-arrowforward-24'
  | 'navigation-arrowforward-40'
  | 'navigation-arrowsort-16'
  | 'navigation-arrowsort-40'
  | 'navigation-arrowup-16'
  | 'navigation-arrowup-40'
  | 'navigation-chevronback-12'
  | 'navigation-chevronback-16'
  | 'navigation-chevronback-24'
  | 'navigation-chevronback-32'
  | 'navigation-chevronback-40'
  | 'navigation-chevronback-48'
  | 'navigation-chevronback-64'
  | 'navigation-chevronback-80'
  | 'navigation-chevrondown-12'
  | 'navigation-chevrondown-16'
  | 'navigation-chevrondown-24'
  | 'navigation-chevrondown-32'
  | 'navigation-chevrondown-40'
  | 'navigation-chevrondown-48'
  | 'navigation-chevrondown-64'
  | 'navigation-chevrondown-80'
  | 'navigation-chevronforward-12'
  | 'navigation-chevronforward-16'
  | 'navigation-chevronforward-24'
  | 'navigation-chevronforward-32'
  | 'navigation-chevronforward-40'
  | 'navigation-chevronforward-48'
  | 'navigation-chevronforward-64'
  | 'navigation-chevronforward-80'
  | 'navigation-chevronup-12'
  | 'navigation-chevronup-16'
  | 'navigation-chevronup-24'
  | 'navigation-chevronup-32'
  | 'navigation-chevronup-40'
  | 'navigation-chevronup-48'
  | 'navigation-chevronup-64'
  | 'navigation-chevronup-80'
  | 'navigation-close-12'
  | 'navigation-close-16'
  | 'navigation-close-24'
  | 'navigation-close-32'
  | 'navigation-close-40'
  | 'navigation-close-48'
  | 'navigation-close-64'
  | 'navigation-close-80'
  | 'navigation-menu-24'
  | 'navigation-menu-32'
  | 'navigation-menu-40'
  | 'navigation-minus-16'
  | 'navigation-minus-24'
  | 'navigation-minus-40'
  | 'navigation-plus-12'
  | 'navigation-plus-16'
  | 'navigation-plus-24'
  | 'navigation-plus-32'
  | 'navigation-plus-40'
  | 'navigation-plus-48'
  | 'nocosts-24'
  | 'nocosts-32'
  | 'nocosts-40'
  | 'nofilter-24'
  | 'nofilter-32'
  | 'nofilter-40'
  | 'nohiddencost-40'
  | 'nohiddencost-80'
  | 'offergeneric-24'
  | 'offergeneric-40'
  | 'office-avinstallation-40'
  | 'office-cleaning-40'
  | 'office-elevator-40'
  | 'office-furniture-40'
  | 'office-homework-24'
  | 'office-homework-40'
  | 'office-lawnmower-40'
  | 'office-meetingroom-40'
  | 'office-moving-24'
  | 'office-moving-40'
  | 'office-newareainsysenv-40'
  | 'office-officearea-40'
  | 'office-officesupplies-40'
  | 'office-otherfacilityfaults-40'
  | 'office-property-40'
  | 'office-propertyinside-40'
  | 'office-propertyoutside-40'
  | 'office-restaurantcafeteria-40'
  | 'office-restroom-40'
  | 'office-retailer-16'
  | 'office-retailer-24'
  | 'office-retailer-40'
  | 'office-securityequipment-40'
  | 'office-servicetools-64'
  | 'office-socialisingkitchenarea-40'
  | 'office-workdesk-40'
  | 'office-workplacelayout-40'
  | 'office-workshop-40'
  | 'office-workshop-80'
  | 'other-24hforwardarrowcircle-32'
  | 'other-24hforwardarrowcircle-40'
  | 'other-360view-24'
  | 'other-360view-40'
  | 'other-airpurifier-32'
  | 'other-airpurifier-40'
  | 'other-animal-40'
  | 'other-asterisk-40'
  | 'other-backplate-24'
  | 'other-bandaid-24'
  | 'other-bandaid-40'
  | 'other-bank-24'
  | 'other-bank-32'
  | 'other-bank-40'
  | 'other-barchart-40'
  | 'other-bookmark-16'
  | 'other-bookmark-24'
  | 'other-branching-24'
  | 'other-calendaronedot-32'
  | 'other-calendaronedot-40'
  | 'other-calendarsevendots-32'
  | 'other-calendarsevendots-40'
  | 'other-calendarthreedots-32'
  | 'other-calendarthreedots-40'
  | 'other-carframed-24'
  | 'other-carshadow-24'
  | 'other-carstacked-24'
  | 'other-chart-16'
  | 'other-chart-24'
  | 'other-chart-40'
  | 'other-childface-24'
  | 'other-childface-40'
  | 'other-circlefilled-16'
  | 'other-circlefilled-24'
  | 'other-circulareconomy-40'
  | 'other-cogdiamond-24'
  | 'other-cogdiamond-40'
  | 'other-cogwheels-24'
  | 'other-cogwheels-40'
  | 'other-contract-32'
  | 'other-contract-40'
  | 'other-creditcard-32'
  | 'other-creditcard-40'
  | 'other-creditcardcirclearrow-32'
  | 'other-creditcardcirclearrow-40'
  | 'other-creditcardplus-32'
  | 'other-creditcardplus-40'
  | 'other-crop-24'
  | 'other-cubedivided-24'
  | 'other-diamond-16'
  | 'other-diamond-24'
  | 'other-diamond-32'
  | 'other-diamond-40'
  | 'other-documentswrench-24'
  | 'other-documentswrench-40'
  | 'other-emission-24'
  | 'other-emission-40'
  | 'other-emission-80'
  | 'other-envoymachine-40'
  | 'other-exchange-24'
  | 'other-eye-16'
  | 'other-eye-24'
  | 'other-eyeslash-16'
  | 'other-eyeslash-24'
  | 'other-fallbackvideo-24'
  | 'other-fire-24'
  | 'other-fire-40'
  | 'other-foldercog-24'
  | 'other-fridge-24'
  | 'other-fridge-40'
  | 'other-glass-24'
  | 'other-glass-40'
  | 'other-grid-16'
  | 'other-handshake-40'
  | 'other-help-16'
  | 'other-home-16'
  | 'other-idcardslash-32'
  | 'other-idcardslash-40'
  | 'other-legal-24'
  | 'other-legal-40'
  | 'other-link-24'
  | 'other-massbalance-40'
  | 'other-photo-24'
  | 'other-profilecog-16'
  | 'other-qrscan-32'
  | 'other-qrscan-40'
  | 'other-receipt-32'
  | 'other-receipt-40'
  | 'other-receiptslash-32'
  | 'other-receiptslash-40'
  | 'other-rose-24'
  | 'other-rose-40'
  | 'other-satellite-32'
  | 'other-satellite-40'
  | 'other-screw-40'
  | 'other-shieldlock-40'
  | 'other-shieldplus-40'
  | 'other-smileyhappy-40'
  | 'other-smileyhappyfilled-40'
  | 'other-smileyneutral-40'
  | 'other-smileyneutralfilled-40'
  | 'other-smileysad-40'
  | 'other-smileysadfilled-40'
  | 'other-smileysomewhathappy-40'
  | 'other-smileysomewhathappyfilled-40'
  | 'other-smileysomewhatsad-40'
  | 'other-smileysomewhatsadfilled-40'
  | 'other-star-32'
  | 'other-star-40'
  | 'other-starfilled-32'
  | 'other-starfilled-40'
  | 'other-starhalffilled-32'
  | 'other-starhalffilled-40'
  | 'other-structure-24'
  | 'other-table-24'
  | 'other-traceablecobolt-40'
  | 'other-trophy-40'
  | 'other-unlocked-24'
  | 'other-unlocked-40'
  | 'other-van-16'
  | 'other-van-24'
  | 'other-van-40'
  | 'other-vanhouse-24'
  | 'other-vanhouse-40'
  | 'ownersmanual-40'
  | 'ownersmanual-64'
  | 'paperclip-16'
  | 'paperclip-24'
  | 'paperclip-40'
  | 'paperdownload-24'
  | 'paperdownload-40'
  | 'paperplane-24'
  | 'paperplane-40'
  | 'paperplanefilled-24'
  | 'paperplanefilled-40'
  | 'papertemplate-40'
  | 'print-16'
  | 'print-24'
  | 'print-32'
  | 'print-40'
  | 'profile-24'
  | 'profile-40'
  | 'recycle-40'
  | 'refresh-16'
  | 'refresh-40'
  | 'remote-32'
  | 'remote-40'
  | 'road-16'
  | 'road-32'
  | 'road-40'
  | 'roadmountains-32'
  | 'roadmountains-40'
  | 'sale-16'
  | 'sale-24'
  | 'sale-32'
  | 'sale-40'
  | 'save-16'
  | 'save-24'
  | 'save-40'
  | 'savings-24'
  | 'savings-32'
  | 'savings-40'
  | 'search-16'
  | 'search-24'
  | 'search-40'
  | 'settings-16'
  | 'settings-24'
  | 'settings-32'
  | 'settings-40'
  | 'share-16'
  | 'share-24'
  | 'share-32'
  | 'share-40'
  | 'share-48'
  | 'share-64'
  | 'share-80'
  | 'shop-16'
  | 'shop-24'
  | 'shop-40'
  | 'smartphone-24'
  | 'smartphone-40'
  | 'speaker-32'
  | 'speaker-40'
  | 'star-40'
  | 'support-16'
  | 'support-24'
  | 'support-32'
  | 'support-40'
  | 'support-64'
  | 'sustainablematerial-40'
  | 'taxes-24'
  | 'taxes-32'
  | 'taxes-40'
  | 'throwback-32'
  | 'throwback-40'
  | 'time-16'
  | 'time-24'
  | 'time-32'
  | 'time-40'
  | 'warninghazard-40'
  | 'warranty-24'
  | 'warranty-32'
  | 'warranty-40'
  | 'warranty-80'
  | 'weather-cloud-40'
  | 'weather-rain-24'
  | 'weather-rain-40'
  | 'weather-snow-24'
  | 'weather-snow-32'
  | 'weather-snow-40'
  | 'weather-sun-32'
  | 'weather-sun-40'
  | 'wireless-40';

/**
 * 0.x icon types that are supported in the automatic transform to 1.0 icon names,
 * including only the icons prefixed with `thirdparty-`.
 *
 * @deprecated Migrate to `BrandedIconName` and separate `size` property instead.
 */
type DEPRECATED_ThirdPartyIconType =
  | 'thirdparty-arios-24'
  | 'thirdparty-blogger-24'
  | 'thirdparty-blogger-40'
  | 'thirdparty-facebook-24'
  | 'thirdparty-googleassistant-16'
  | 'thirdparty-googleassistant-40'
  | 'thirdparty-instagram-24'
  | 'thirdparty-line-24'
  | 'thirdparty-line-40'
  | 'thirdparty-linkedin-24'
  | 'thirdparty-linkedin-40'
  | 'thirdparty-pinterest-24'
  | 'thirdparty-salesforce-16'
  | 'thirdparty-salesforce-40'
  | 'thirdparty-threads-24'
  | 'thirdparty-twitter-24'
  | 'thirdparty-vkontakte-24'
  | 'thirdparty-vkontakte-40'
  | 'thirdparty-wechat-24'
  | 'thirdparty-wechat-40'
  | 'thirdparty-weibo-24'
  | 'thirdparty-weibo-40'
  | 'thirdparty-x-24'
  | 'thirdparty-youtube-24';

// These will remain in place until 2.0, to ease the migration period
export type {
  DEPRECATED_IconType,
  DEPRECATED_IconButtonIcon,
  DEPRECATED_ThirdPartyIconType,
};

const oldToNewIconMap: Record<string, IconName> = {
  '30daystrial': 'calendar-date',
  '360camera': 'camera-rotate',
  alarm: 'bell',
  avatar: 'profile',
  award: 'star-circled',
  blocked: 'blocked',
  calendar: 'calendar',
  'car-abs': 'abs',
  'car-alcolock': 'alcohol-lock',
  'car-aqi': 'aqi-circled',
  'car-audio': 'audio-wave',
  'car-awd': 'awd',
  'car-battery': 'battery-frame-standing',
  'car-batterycharging': 'charging-battery-standing',
  'car-batterysoftware': 'cloud-download-battery',
  'car-blis': 'blis',
  'car-camera': 'camera',
  'car-car': 'car',
  'car-carbattery': 'car-battery',
  'car-carclock': 'clock-car',
  'car-cardroplets': 'drops-car',
  'car-carelectricavatar': 'profile-car',
  'car-carelectricclock': 'clock-car',
  'car-carelectricthreearrows': 'arrows-multidirectional-car',
  'car-carelectrictwoarrows': 'swap-car',
  'car-carinhouse': 'garage-car',
  'car-carinspection': 'search-car',
  'car-carmagnifier': 'search-car',
  'car-carshieldtick': 'shield-checked-car',
  'car-carside': 'car-side-profile',
  'car-carunderroof': 'roof-car',
  'car-carwash': 'drops-car',
  'car-ccs': 'charger-ccs',
  'car-charging': 'charging-battery-standing',
  'car-chargingsocketcn': 'charger-cn',
  'car-chargingsocketcneu': 'charger-cn-eu',
  'car-chargingsocketeu': 'charger-eu',
  'car-chargingsocketjp': 'charger-jp',
  'car-chargingsocketjpna': 'charger-jp-na',
  'car-chargingsocketna': 'charger-na',
  'car-citysafety': 'human-framed',
  'car-collisionavoidence': 'evasive-manoeuvre',
  'car-courtesycar': 'clock-car',
  'car-cta': 'cta',
  'car-driveralert': 'warning',
  'car-electric': 'plug',
  'car-electricalplug': 'plug',
  'car-engine': 'engine',
  'car-fan': 'fan',
  'car-features': 'checklist-paper',
  'car-fuel': 'fuel-pump',
  'car-fwd': 'fwd',
  'car-houseelectricplug': 'home-charger',
  'car-hud': 'hud',
  'car-hybrid': 'fuel-pump-hybrid',
  'car-key': 'key',
  'car-moonroof': 'moon-roof',
  'car-navigate': 'navigation-arrow',
  'car-performance': 'speedo',
  'car-plugsurfingcard': 'plug',
  'car-psquare': 'parking-squared',
  'car-road': 'road',
  'car-roadsideassistance': 'tow-truck',
  'car-safetyfeatures': 'car-sensors',
  'car-seat': 'seat',
  'car-seatright': 'seat-right',
  'car-seats': 'seat',
  'car-seatsfour': 'seat-4',
  'car-seatsseven': 'seat-7',
  'car-service': 'wrench',
  'car-size': 'car',
  'car-slipperyroad': 'esc',
  'car-speedcap': 'speedo',
  'car-steeringwheel': 'steering-wheel',
  'car-steeringwheelp': 'parking-steering-wheel',
  'car-towbar': 'arrows-towbar',
  'car-traffic': 'cars',
  'car-transmission': 'manual-transmission',
  'car-trunkspace': 'trunk-space',
  'car-type2': 'charger-cn-eu',
  'car-tyre': 'tire',
  'car-wheel': 'wheel',
  'car-windscreenwiper': 'windscreen-wiper',
  checkmark: 'checkmark',
  circleplus: 'plus-circled',
  citydriving: 'city',
  coffee: 'coffee-cup',
  'communication-chat': 'speech-bubble-three-dots',
  'communication-email': 'email',
  'communication-mailhandling': 'mail',
  'communication-phone': 'phone',
  'communication-textmessage': 'message-phone',
  connectivity: 'globe',
  copy: 'stacked-squares',
  date: 'checked-calendar',
  delete: 'trash-can',
  designyourown: 'pen-circled',
  device: 'device',
  digitalkey: 'digital-key',
  ecoleather: 'leather',
  edit: 'pen-paper',
  electrification: 'lightning-bolt',
  error: 'exclamation-mark-circled',
  extendedperiod: 'clock-circle-forward-large',
  externallink: 'external-link',
  facemask: 'mask',
  fallbackimage: 'image-framed',
  favorite: 'heart',
  filter: 'filter',
  guardingservices: 'shield',
  highwaypilot: 'highway',
  incentives: 'bills-circle-backwards',
  info: 'info-circled',
  insurance: 'shield-checked',
  leaf: 'leaf',
  link: 'link',
  list: 'list',
  locked: 'lock',
  'map-crosshair': 'crosshair',
  'map-map': 'map',
  'map-mappin': 'map-pin',
  'map-pegman': 'pegman',
  'map-route': 'route',
  'media-pause': 'pause',
  'media-play': 'play',
  'media-stepbackward': 'step-backward',
  'media-stepforward': 'step-forward',
  mixeddriving: 'mountains-straight-road',
  moneycirculararrows: 'bills-circle-forward',
  more: 'three-dots',
  mountains: 'mountains',
  multiplechoice: 'arrows-multidirectional',
  'navigation-arrowback': 'arrow-back',
  'navigation-arrowdown': 'arrow-down',
  'navigation-arrowforward': 'arrow-forward',
  'navigation-arrowsort': 'arrows-down-up',
  'navigation-arrowup': 'arrow-up',
  'navigation-chevronback': 'chevron-back',
  'navigation-chevrondown': 'chevron-down',
  'navigation-chevronforward': 'chevron-forward',
  'navigation-chevronup': 'chevron-up',
  'navigation-close': 'x',
  'navigation-menu': 'menu',
  'navigation-minus': 'minus',
  'navigation-plus': 'plus',
  nocosts: 'bills-slashed',
  nofilter: 'x-search',
  nohiddencost: 'checklist',
  offergeneric: 'percentage-circled',
  'office-avinstallation': 'projector',
  'office-cleaning': 'broom-and-bucket',
  'office-elevator': 'elevator',
  'office-furniture': 'office-chair',
  'office-homework': 'home-work',
  'office-lawnmower': 'lawnmower',
  'office-meetingroom': 'conference-table',
  'office-moving': 'delivery-truck',
  'office-newareainsysenv': 'plus-circled-square-dotted',
  'office-officearea': 'desks',
  'office-officesupplies': 'office-supplies',
  'office-otherfacilityfaults': 'box-list-papers',
  'office-property': 'office-building',
  'office-propertyinside': 'tools',
  'office-propertyoutside': 'garden-tools',
  'office-restaurantcafeteria': 'fork-and-knife',
  'office-restroom': 'restroom',
  'office-retailer': 'retailer',
  'office-securityequipment': 'lock-shield',
  'office-servicetools': 'wrench-and-screwdriver',
  'office-socialisingkitchenarea': 'kitchen',
  'office-workdesk': 'workstation',
  'office-workplacelayout': 'loop-backwards-square-dotted',
  'office-workshop': 'wrench-house',
  'other-24hforwardarrowcircle': 'clock-circle-forward-24h',
  'other-360view': 'cube-3d',
  'other-airpurifier': 'air-filter',
  'other-animal': 'animal',
  'other-asterisk': 'asterisk',
  'other-backplate': 'image-framed',
  'other-bandaid': 'band-aid',
  'other-bank': 'bank',
  'other-barchart': 'graph-bars',
  'other-bookmark': 'bookmark',
  'other-branching': 'arrows-split',
  'other-calendaronedot': 'calendar-1-dot',
  'other-calendarsevendots': 'calendar-7-dots',
  'other-calendarthreedots': 'calendar-3-dots',
  'other-carframed': 'car-framed',
  'other-carshadow': 'car-shadow',
  'other-carstacked': 'stacked-squares-car',
  'other-chart': 'graph-lines',
  'other-childface': 'child',
  'other-circle': 'circle',
  'other-circulareconomy': 'loop-forward-cube',
  'other-cogdiamond': 'diamond-cog',
  'other-cogwheels': 'cogs',
  'other-contract': 'signed-paper',
  'other-creditcard': 'credit-card',
  'other-creditcardcirclearrow': 'circle-backwards-credit-card',
  'other-creditcardplus': 'plus-credit-card',
  'other-crop': 'crop',
  'other-cubedivided': 'cube-3d-divided',
  'other-diamond': 'diamond',
  'other-documentswrench': 'wrench-papers',
  'other-emission': 'co2',
  'other-envoymachine': 'checkmark-device',
  'other-exchange': 'loop-backwards-rounded',
  'other-eye': 'eye',
  'other-eyeslash': 'eye-slashed',
  'other-fallbackvideo': 'video-framed',
  'other-fire': 'fire',
  'other-foldercog': 'cog-folder',
  'other-fridge': 'fridge',
  'other-glass': 'broken-glass',
  'other-grid': 'square-grid',
  'other-handshake': 'handshake',
  'other-help': 'question-mark-circled',
  'other-home': 'home',
  'other-idcardslash': 'id-card-slashed',
  'other-legal': 'legal-hammer',
  'other-link': 'share',
  'other-massbalance': 'scale',
  'other-photo': 'photo',
  'other-profilecog': 'cog-profile',
  'other-qrscan': 'qr-code-scanner',
  'other-receipt': 'receipt',
  'other-receiptslash': 'receipt-slashed',
  'other-rose': 'rose',
  'other-satellite': 'satellite',
  'other-screw': 'bolt',
  'other-shieldlock': 'lock-shield',
  'other-shieldplus': 'shield-plus',
  'other-smileyhappy': 'smiley-happy',
  'other-smileyneutral': 'smiley-neutral',
  'other-smileysad': 'smiley-sad',
  'other-smileysomewhathappy': 'smiley-slightly-happy',
  'other-smileysomewhatsad': 'smiley-slightly-sad',
  'other-star': 'star',
  'other-starhalf': 'star-half',
  'other-structure': 'structure',
  'other-table': 'table',
  'other-traceablecobolt': 'cobalt-search',
  'other-trophy': 'trophy',
  'other-unlocked': 'lock-open',
  'other-van': 'delivery-truck',
  'other-vanhouse': 'delivery-truck',
  ownersmanual: 'book',
  paperclip: 'paper-clip',
  paperdownload: 'download-paper',
  paperplane: 'paper-plane',
  papertemplate: 'papers',
  print: 'printer',
  profile: 'profile-circled',
  recycle: 'recycle',
  refresh: 'loop-forward',
  remote: 'mobile-phone-connected',
  road: 'road',
  roadmountains: 'mountains-road',
  sale: 'hangtag',
  save: 'download',
  savings: 'bills',
  search: 'search',
  settings: 'cog',
  share: 'external-link',
  shop: 'shopping-bag',
  smartphone: 'mobile-phone',
  speaker: 'speaker',
  star: 'star',
  support: 'headset',
  sustainablematerial: 'fabric',
  taxes: 'usd-coin',
  throwback: 'leaf-loop-backward',
  time: 'clock',
  warninghazard: 'warning-triangle',
  warranty: 'ribbon',
  'weather-cloud': 'cloud',
  'weather-rain': 'rain',
  'weather-snow': 'snowflake',
  'weather-sun': 'sun',
  wireless: 'wifi',
};

const oldThirdPartyToNewIconMap: Record<string, BrandedIconName> = {
  'thirdparty-arios': 'apple-ar',
  'thirdparty-blogger': 'blogger',
  'thirdparty-facebook': 'facebook',
  'thirdparty-googleassistant': 'google-assistant',
  'thirdparty-instagram': 'instagram',
  'thirdparty-line': 'line',
  'thirdparty-linkedin': 'linkedin',
  'thirdparty-pinterest': 'pinterest',
  'thirdparty-salesforce': 'salesforce',
  'thirdparty-threads': 'threads',
  'thirdparty-twitter': 'x-app',
  'thirdparty-vkontakte': 'vkontakte',
  'thirdparty-wechat': 'wechat',
  'thirdparty-weibo': 'weibo',
  'thirdparty-x': 'x-app',
  'thirdparty-youtube': 'youtube',
};

/**
 * Accepts a string in the format of `category-name-size`, used in 0.x, and returns the
 * corresponding properties to render 1.0 icons.
 */
export function getIconPropsFromDeprecatedIconType(
  type?: DEPRECATED_IconType
): Pick<IconProps, 'icon' | 'size' | 'filled'> {
  if (!type) {
    return {} as IconProps;
  }
  let categoryAndName: keyof typeof oldToNewIconMap;
  let filled: boolean | undefined;
  const parts = type.split('-');
  if (parts.length === 2) {
    categoryAndName = parts[0];
  } else {
    categoryAndName = [parts[0], parts[1]].join('-');
  }
  const size = Number(parts.pop()) as NonNullable<IconProps['size']>;
  let icon = oldToNewIconMap[categoryAndName];
  if (!icon && categoryAndName.endsWith('filled')) {
    icon = oldToNewIconMap[categoryAndName.replace('filled', '')];
    if (icon) {
      filled = true;
    }
  }
  return {
    icon,
    size,
    filled,
  };
}

/**
 * Accepts a string in the format of `category-name-size`, used in 0.x, and returns the
 * corresponding properties to render 1.0 icons.
 */
export function getBrandedIconPropsFromDeprecatedIconType(
  type?: DEPRECATED_ThirdPartyIconType
): Pick<BrandedIconProps, 'icon' | 'size'> {
  if (!type) {
    return {} as BrandedIconProps;
  }
  const parts = type.split('-');
  const categoryAndName = [parts[0], parts[1]].join('-');
  const size = Number(parts.pop()) as IconProps['size'];
  const icon = oldThirdPartyToNewIconMap[categoryAndName];
  return {
    icon,
    size,
  } as BrandedIconProps;
}

/**
 * Accepts a string in the format of `category-name`, used in 0.x, and returns the
 * corresponding properties to render 1.0 icons.
 */
export function getIconButtonPropsFromDeprecatedIconType(
  type?: DEPRECATED_IconButtonIcon
): Pick<IconButtonProps, 'icon'> {
  if (!type) {
    return {} as IconButtonProps;
  }
  let icon = oldToNewIconMap[type];
  return {
    icon,
  };
}
