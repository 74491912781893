import { cssMerge } from '@volvo-cars/css/utils';
import {
  AccordionDetails,
  type BaseAccordionDetailsProps,
} from '../accordion-details';

export interface SteppedAccordionDetailsProps
  extends BaseAccordionDetailsProps {
  /**
   * Whether the details are open.
   */
  open: boolean;
}
/**
 * SteppedAccordionDetails is used within a [SteppedAccordion](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-stepped-accordion-stepped-accordion--docs) component.
 */
export function SteppedAccordionDetails({
  children,
  className,
  style,
  ...props
}: SteppedAccordionDetailsProps) {
  return (
    <AccordionDetails
      {...props}
      className={cssMerge(
        'border-b-0 border-l-2 transition-colors -ml-2 border-transparent open:border-primary',
        className
      )}
    >
      {children}
    </AccordionDetails>
  );
}
