import { useEffect, useRef, useState } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { type AccordionSummaryProps } from '../accordion-summary';

export interface SteppedAccordionSummaryProps extends AccordionSummaryProps {}

/**
 * SteppedAccordionSummary is used within a [SteppedAccordion](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-stepped-accordion-stepped-accordion--docs) component.
 *
 * It renders a [`summary`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary) element
 * which is used to represent the content of a step in the accordion. It also includes the `aria-disabled` attribute to indicate whether the step is currently active, therefore can not be closed.
 */
export function SteppedAccordionSummary({
  children,
  className,
  ...props
}: SteppedAccordionSummaryProps) {
  const ref = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const parentElement = ref.current?.parentElement;

    if (parentElement) {
      const toggleHandler = (event: Event) => {
        setIsOpen((event.currentTarget as HTMLDetailsElement).open);
      };

      parentElement.addEventListener('toggle', toggleHandler);

      return () => {
        parentElement.removeEventListener('toggle', toggleHandler);
      };
    }
  }, []);

  return (
    <summary
      {...props}
      ref={ref}
      aria-disabled={isOpen}
      tabIndex={isOpen ? -1 : undefined}
      className={cssMerge(
        'font-20 px-24 font-medium py-8',
        !isOpen && 'tap-area',
        className
      )}
    >
      {children}
    </summary>
  );
}
