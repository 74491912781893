import { cssMerge } from '@volvo-cars/css/utils';

export interface SteppedAccordionProps {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}

/**
 * Create a Stepped Accordion using the [SteppedAccordion](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-stepped-accordion-stepped-accordion--docs) component to handle the border, vertical direction and gap.
 */
export function SteppedAccordion({
  children,
  className,
  ...props
}: SteppedAccordionProps) {
  return (
    <div
      {...props}
      className={cssMerge('border-l-2 flex-col gap-8', className)}
    >
      {children}
    </div>
  );
}
