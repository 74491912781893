import { toKebabCase } from './to-kebab-case';

export interface Tokens {
  [key: string]: string;
}

const tokens: Tokens = new Proxy(Object.create(null), {
  get(_target, key: string) {
    return `var(--v-${toKebabCase(key)})`;
  },
});

export default tokens;
